/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  UserIcon,
  UserGroupIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { graphql, useStaticQuery } from "gatsby"

const groepen = [
  {
    name: 'Sloebers',
    age: '1ste en 2de leerjaar',
    href: '/sloebers',
    icon: UserIcon,
  },
  {
    name: 'Korbels',
    age: '3de en 4de leerjaar',
    href: '/korbels',
    icon: UserIcon,
  },
  {
    name: "Rakwi's",
    age: '5de en 6de leerjaar',
    href: '/rakwis',
    icon: UserIcon,
  },
  {
    name: "Tito's",
    age: '1ste en 2de middelbaar',
    href: '/titos',
    icon: UserGroupIcon,
  },
  {
    name: "Keti's",
    age: '3de en 4de middelbaar',
    href: '/ketis',
    icon: UserGroupIcon,
  },
  {
    name: "Aspi's",
    age: '5de en 6de middelbaar',
    href: '/aspis',
    icon: UserGroupIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const query = graphql`
query InschrijvingslinkQuery {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/dashboard/"}}) {
    edges {
      node {
        frontmatter {
          inschrijvingslink
        }
      }
    }
  }
}
`

const NavBar = () => {
  const data = useStaticQuery(query)
  const inschrijvingslink = data.allMarkdownRemark.edges[0].node.frontmatter.inschrijvingslink

  return (
    <Popover className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <span className="sr-only">Chiro Doeritsan</span>
              <img
                className="h-10 w-auto sm:h-12"
                src="https://chiro.be/sites/default/files/2018-03/Chirologo_1200px.png"
                alt="Homepage"
              />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <a href="/info" className="text-base font-medium text-gray-500 hover:text-gray-900 py-1">
              Info
            </a>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 py-1'
                    )}
                  >
                    <span>Groepen</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {groepen.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 text-red-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.age}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <a href="/verhuurinfo" className="text-base font-medium text-gray-500 hover:text-gray-900 py-1">
              Verhuur
            </a>
            <a href="/kalender" className="text-base font-medium text-gray-500 hover:text-gray-900  py-1">
              Kalender
            </a>
            <a
                  href={inschrijvingslink}
                  className="w-full flex items-center justify-center px-4 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                >
                  Inschrijven
                </a>

          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://chiro.be/sites/default/files/2018-03/Chirologo_1200px.png"
                    alt="Workflow"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a href="/info" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Info
                </a>
                <a href="/groepen" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Groepen
                </a>  
                <a href="/verhuurinfo" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Verhuur
                </a>
                <a href="/kalender" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Kalender
                </a>
                <a href="/kamp" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Kamp
                </a>
              </div>
              <div>
                <a
                  href={inschrijvingslink}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                >
                  Inschrijven
                </a>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default NavBar