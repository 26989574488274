import * as React from "react"
import NavBar from "../components/navbar"

const Layout = ({ pageTitle, children }) => {
  return (
    <div>
      <title>{pageTitle}</title>
      <NavBar></NavBar>
      <div class="max-w-6xl mx-auto">
        {children}
      </div>
    </div>
  )
}
export default Layout